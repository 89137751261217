import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import useCalculatorData from "../hooks/useCalculatorData";
import GrothGraph from "./Chart";
import Results from "./Results";
import InputField from "./InputField";
import Footer from "./Footer";

const WIDTH = "max-w-[210px] w-full";

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function App() {
  // State for user input data
  const [userData, setUserData] = useState({
    currentAge: 40,
    currentBTC: "0.5000", // Keeping it as a string to preserve formatting
    monthlyExpenses: 20000,
    monthlyContribution: 1000,
    retirementAge: 65,
    expectancyAge: 85,
    annualInflation: 5,
    forecastBTCPrice: 0,
    isHidden: true,
  });

  const { outputData, btcGrowthData, btcDecreaseData, chartData } =
    useCalculatorData(userData);

  const toggleVisibility = () => {
    setUserData((prevData) => ({
      ...prevData,
      isHidden: !prevData.isHidden,
    }));
  };

  const handleIncrease = () => {
    setUserData((prevData) => ({
      ...prevData,
      monthlyContribution: prevData.monthlyContribution + 1000,
    }));
  };

  const handleDecrease = () => {
    setUserData((prevData) => ({
      ...prevData,
      monthlyContribution: Math.max(prevData.monthlyContribution - 1000, 0),
    }));
  };

  const handleMonthlyContributionChange = (newValue) => {
    if (typeof newValue === 'string') {
      const numericValue = parseInt(newValue.replace(/,/g, ""), 10) || 0;
      setUserData((prevData) => ({
        ...prevData,
        monthlyContribution: numericValue,
      }));
    }
  };

  const handleCurrentBTCChange = (newValue) => {
    // Ensure newValue is a string and update currentBTC directly
    setUserData((prevData) => ({
      ...prevData,
      currentBTC: newValue,
    }));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col md:flex-row">
        <div className="max-w-[100%] w-[100%] pb-4 bg-[#F8F8F8] box-border  text-[#232526] px-[16px] pr-[32px] pt-[32px] md:max-w-[513px] flex flex-col items-center lg:pl-[60px] pt-[70px]">
          <div className="mr-[auto]">
            <h1 className="text-[30px] font-bold leading-9">Bitcoin</h1>
            <h2 className="text-[20px] font-normal leading-8 text-[#787878]">
              Retirement Calculator
            </h2>
          </div>
          <div className="w-full mt-[2.5rem] mb-[2.5rem]">
            <h2 className="font-[600] text-[22px] text-[#232526]">
              Do you have enough BTC to retire?
            </h2>
            <div className="flex items-center gap-4 my-7">
              <InputField
                label="Your current age"
                value={userData.currentAge}
                onChange={(newValue) =>
                  setUserData((prevData) => ({
                    ...prevData,
                    currentAge: Number(newValue),
                  }))
                }
                className={WIDTH}
              />

              <InputField
                label="Your current BTC stack"
                value={userData.currentBTC}
                onChange={handleCurrentBTCChange} // Handle BTC change
                suffix="BTC"
                className={WIDTH}
                formatting={false} // Disable automatic formatting
              />
            </div>
            <InputField
              label="Your ideal monthly expenses during retirement"
              value={userData.monthlyExpenses}
              onChange={(newValue) =>
                setUserData((prevData) => ({
                  ...prevData,
                  monthlyExpenses: newValue === "" ? "" : Number(newValue),
                }))
              }
              prefix="$"
              suffix="USD"
            />

            <div className="flex items-center justify-center mb-4 mt-4 relative">
              <button
                onClick={handleIncrease}
                className="px-3 py-1 text-gray-600 rounded-r text-[40px] absolute right-0 bottom-0 flex items-center justify-center h-[60px]"
                aria-label="Increase"
              >
                +
              </button>
              <InputField
                value={formatNumberWithCommas(userData.monthlyContribution)}
                label="Your monthly contribution"
                onChange={handleMonthlyContributionChange}
                prefix="$"
                increaseDecrease={true}
                className="w-full"
                className2="px-24"
              />
              <button
                onClick={handleDecrease}
                className="px-3 py-1 text-gray-600 rounded-r text-[40px] absolute left-0 bottom-0 flex items-center justify-center h-[60px]"
                aria-label="Decrease"
              >
                -
              </button>
            </div>
          </div>
          <div className="flex items-start justify-start flex-col w-[100%] max-w[324px] ">
            <div className="flex  mb-4">
              <h3 className="text-[#787878] font-[500] pe-2 leading-[24px] text-[18px]">
                Assumptions
              </h3>
              <button onClick={toggleVisibility} className="focus:outline-none">
                <img
                  src="/images/angle-down.svg"
                  alt="Download Icon"
                  className={` h-4 w-4 text-gray-600 transform transition-transform duration-300 ${
                    userData.isHidden ? "" : "rotate-180"
                  }`}
                />
              </button>
            </div>
            <CSSTransition
              in={!userData.isHidden}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className="fade">
                <div className="flex items-center gap-4">
                  <InputField
                    label="Retirement age"
                    value={userData.retirementAge}
                    onChange={(newValue) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        retirementAge: Number(newValue),
                      }))
                    }
                    className={WIDTH}
                  />
                  <InputField
                    label="Life expectancy age"
                    value={userData.expectancyAge}
                    onChange={(newValue) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        expectancyAge: Number(newValue),
                      }))
                    }
                    className={WIDTH}
                  />
                </div>
                <div className="flex items-center gap-4 mb-4 mt-4">
                  <InputField
                    label="Annual inflation"
                    value={userData.annualInflation}
                    onChange={(newValue) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        annualInflation: Number(newValue),
                      }))
                    }
                    suffix="%"
                    className={WIDTH}
                  />
                  <div className={WIDTH}>
                    <label className="block text-[#787878] text-[14px] font-[400] mb-2">
                      Forecast BTC price
                    </label>
                    <select
                      value={userData.forecastBTCPrice}
                      disabled
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          forecastBTCPrice: Number(e.target.value),
                        }))
                      }
                      className="border-[1px] appearance-none rounded-[16px] w-full p-[18px] bg-[#FFFFFF] font-[600] tex-[18px] text-[#232526] outline-0 h-[60px]"
                    >
                      <option value={0}>Power Law</option>
                      <option value={1}>Linear Growth</option>
                      <option value={2}>Exponential Growth</option>
                    </select>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>

        <div className="w-[100%] max-w-[927px] bg-[white] px-2 md:px-6 lg:px-8">
          <Results
            currentBTCValue={formatNumberWithCommas(
              (outputData.currentBTCValue * parseFloat(userData.currentBTC)).toFixed(0) // Parse currentBTC as float for calculation
            )}
            portfolioBalance={formatNumberWithCommas(
              outputData.portfolioBalance.toFixed(0)
            )}
            isEnoughToRetire={outputData.isEnoughToRetire}
            retirementAge={userData.retirementAge}
          />
          <div className="sm:w-[100%] border-[1px] border-[#E5E5E5] p-2 rounded-[24px]">
            {chartData?.data  && <GrothGraph
              btcGrowthData={btcGrowthData}
              btcDecreaseData={btcDecreaseData}
              chartData={chartData}              
            /> }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
