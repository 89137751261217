import React from "react";

function Results({
  currentBTCValue,
  portfolioBalance,
  isEnoughToRetire,
  retirementAge,
}) {
  return (
    <div className="pt-[60px] flex items-center flex-wrap justify-between gap-4 mb-[22px]">
      <div className="sm:w-[270px] w-full rounded-[20px] border-[1px] p-[18px] box-border flex flex-col items-baseline">
        <img src="/images/bitcoin-circle.svg" className="w-[30px]" />
        <h3 className="text-[#787878] font-[400] text-[14px] pt-3">
          Current BTC portfolio value
        </h3>
        <div className="flex justify-center items-baseline tracking-normal ">
          <h1 className="text-[#FC6702] text-[28px] font-[600]">$</h1>{" "}
          <h1 className="text-[#232526] text-[28px] px-2 font-[600] font-[sans-serif]">
            {currentBTCValue}
          </h1>
          <span className="text-[#787878] text-[16px] font-[400] pl-1">
            USD
          </span>
        </div>
      </div>

      <div className="sm:w-[270px] w-full rounded-[20px] border-[1px] p-[18px] box-border flex flex-col items-baseline">
        <img src="/images/line-chart-dots-circle.svg" className="w-[30px]" />
        <h3 className="text-[#787878] font-[400] text-[14px] pt-3">
          Portfolio balance at age {retirementAge}
        </h3>
        <div className="flex justify-center items-baseline tracking-normal ">
          <h1 className="text-[#FC6702] text-[28px] font-[600]">$</h1>{" "}
          <h1 className="text-[#232526] text-[28px] px-2 font-[600] font-[sans-serif]">
            {portfolioBalance}
          </h1>
          <span className="text-[#787878] text-[16px] font-[400] pl-1">
            USD
          </span>
        </div>
      </div>

      <div className="sm:w-[270px] w-full rounded-[16px] border-[1px] p-[18px] box-border flex flex-col items-baseline">
        <img src="/images/question-circle.svg" className="w-[30px]" />
        <h3 className="text-[#787878] font-[400] text-[14px] pt-3">
          Is my BTC stack is enough to retire?{" "}
        </h3>
        <div className="flex justify-center items-baseline tracking-normal ">
          <h1
            className="text-[30px] font-[600]"
            style={{ color: isEnoughToRetire ? "#5FCF65" : "#DC2626" }}
          >
            {isEnoughToRetire ? "Yes" : "No"}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Results;
